<template>
    <b-form @submit.prevent="save" v-if="formData">
        <b-row>
            <app-access-control xl="2" lg="3" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_PROPERTY}"
                                v-slot:default="props">
                <label>{{$t('PROPERTY')}}</label>
                <app-select mode="search"
                            :disabled="props.disabled" v-model="formData.property"
                            :search="searchProperty"
                            :search-on-create="preloadPropertyList"
                            :search-clear="true"></app-select>
            </app-access-control>

            <app-access-control xl="2" lg="3" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_UNIT}"
                                v-slot:default="props">
                <app-input-control :error-object="validation.unit">
                    <template v-slot:input="data">
                        <label>{{$t('UNIT')}}</label>
                        <app-select :disabled="loading || !formData.property.id || props.disabled" :options="unitList"
                                    :state="data.state"
                                    v-model="formData.unit.id"></app-select>
                    </template>
                </app-input-control>

            </app-access-control>

            <app-access-control xl="2" lg="3" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_PERIOD}"
                                v-slot:default="props">
                <app-input-control :error-object="validation.period">
                    <template v-slot:input="data">
                        <label>{{$t("PERIOD")}}</label>
                        <app-date-range-picker :disabled="props.disabled" v-model="period"
                                               :state="data.state"></app-date-range-picker>
                    </template>
                </app-input-control>
            </app-access-control>

            <app-access-control xl="2" lg="3" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_PERIOD}"
                                v-slot:default="props">
                <label>{{$t("STAY")}}</label>
                <app-number-input :value="nights" disabled :append="$t('NIGHTS')" align="right"></app-number-input>
            </app-access-control>

            <app-access-control xl="2" lg="3" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_ARRIVAL_TIME}"
                                v-slot:default="props">
                <label>{{$t("ARRIVAL_TIME")}}</label>
                <time-picker :disabled="props.disabled" v-model="formData.check_in_time"></time-picker>
            </app-access-control>

            <app-access-control xl="2" lg="3" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_DEPARTURE_TIME}"
                                v-slot:default="props">
                <label>{{$t("DEPARTURE_TIME")}}</label>
                <time-picker :disabled="props.disabled" v-model="formData.check_out_time"></time-picker>
            </app-access-control>
        </b-row>

        <b-row>
            <app-access-control xl="2" lg="3" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_RATE_PLAN}"
                                v-slot:default="props">
                <label>{{$t('RATE_PLAN')}}</label>
                <app-select mode="search" text-field="name" v-model="formData.rate_plan"
                            :search-empty-item="false" :disabled="props.disabled"></app-select>
            </app-access-control>

            <app-access-control xl="2" lg="3" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_STATUS}"
                                v-slot:default="props">
                <label>{{$t('STATUS')}}</label>
                <app-select text-field="label" :options="statusList"
                            :disabled="props.disabled" v-model="formData.status.id"
                            :search-empty-item="false"></app-select>
            </app-access-control>

            <app-access-control xl="2" lg="3" md="6" class="mb-4" v-if="ac_cm_status_value"
                                v-slot:default="props">
                <app-input-control :error-object="validation.cm_product_status">
                    <template v-slot:input="data">
                        <label>{{ $t('CANCELED_BY')}}</label>
                        <app-select class="mt-1" text-field="label" :options="cmStatusList" :state="data.state"
                                    :disabled="props.disabled" v-model="formData.cm_product_status.id"
                                    :search-empty-item="false"></app-select>
                    </template>
                </app-input-control>
            </app-access-control>
        </b-row>

        <form-header>{{$t('TOTAL')}}</form-header>

        <b-row>
            <app-access-control xl="2" lg="3" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_CURRENCY}"
                                v-slot:default="props">
                <label>{{ $t('CURRENCY') }}</label>
                <app-select mode="search" :search="searchCurrency" :disabled="props.disabled"
                            v-model="formData.channel_currency"
                            :search-empty-item="false" search-on-create
                            text-field="code"></app-select>
            </app-access-control>

            <b-col lg="2" md="6" class="mb-4" v-if="currencyCA.disabled === false">
                <label>{{$t("EXCHANGE_RATE_SUGGESTION")}}</label>
                <app-select :options="exchangeRateList" :disabled="!exchangeRateList.length || !!currencyCA.disabled"
                            v-model="currencyValueSuggestion" @input="formData.currency_value = $event.value"
                            value-field="value" return-type="object" text-field="value" :search-empty-item="false">
                    <template v-slot:text="data">
                        {{getExchangeRateText(data.item)}}
                    </template>
                </app-select>
            </b-col>

            <app-access-control xl="2" lg="3" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_EXCHANGE_RATE}"
                                v-slot:default="props" @updated="currencyCA = $event">
                <app-input-control :error-object="validation.currency_value">
                    <template v-slot:input="data">
                        <label>{{$t("EXCHANGE_RATE")}}</label>
                        <app-number-input v-model="formData.currency_value" :state="data.state" align="right"
                                          :disabled="formData.channel_currency.id === company.main_currency.id || loadingCurrency || !!currencyCA.disabled"
                                          allow-decimal :min="0" :decimals="9">
                        </app-number-input>
                    </template>
                </app-input-control>
            </app-access-control>


            <app-access-control xl="2" lg="3" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_TOTAL}"
                                v-slot:default="props">
                <label>{{$t("TOTAL")}}</label>
                <div v-if="formData && formData.hasOwnProperty('db_owner_total')">
                    <app-number-input v-if="formData.db_owner_total && formData.db_owner_total.hasOwnProperty('channel') && formData.db_owner_total.channel"
                                      :disabled="props.disabled" v-model="formData.db_owner_total.channel"
                                      :append="formData.channel_currency.code" :min="0"
                                      allow-decimal align="right">
                    </app-number-input>

                    <app-number-input v-else :disabled="props.disabled" v-model="formData.db_owner_total"
                                      :append="formData.channel_currency.code" :min="0"
                                      allow-decimal align="right">
                    </app-number-input>
                </div>
                <div v-else>
                <app-number-input v-if="formData.total_gross && formData.total_gross.hasOwnProperty('channel') && formData.total_gross.channel"
                                  :disabled="props.disabled" v-model="formData.total_gross.channel"
                                  :append="formData.channel_currency.code" :min="0"
                                  allow-decimal align="right">
                </app-number-input>
                <app-number-input v-else :disabled="props.disabled" v-model="formData.total_gross"
                                  :append="formData.channel_currency.code" :min="0"
                                  allow-decimal align="right">
                </app-number-input>
                </div>
            </app-access-control>

            <app-access-control xl="2" lg="3" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_TOTAL}"
                                v-slot:default="props">
                <template v-if="company.main_currency.id !== formData.channel_currency.id">
                    <label>&zwnj;</label>
                    <div v-if="formData && formData.hasOwnProperty('db_owner_total')">
                        <app-number-input
                            v-if="formData.db_owner_total && formData.db_owner_total.hasOwnProperty('company') && formData.db_owner_total.company"
                            v-model="formData.db_owner_total.company" :append="formData.company_currency.code"
                            allow-decimal align="right" disabled>
                        </app-number-input>
                        <app-number-input
                            v-else
                            :value="Number((formData.db_owner_total * (formData.currency_value || 1))).toFixed(2)" :append="formData.company_currency.code"
                            allow-decimal align="right" disabled>
                        </app-number-input>
                    </div>
                    <div v-else>
                    <app-number-input
                        v-if="formData.total_gross && formData.total_gross.hasOwnProperty('channel') && formData.total_gross.channel"
                        :value="Number((formData.total_gross.channel * (formData.currency_value || 1))).toFixed(2)" :append="formData.company_currency.code"
                        allow-decimal align="right" disabled>
                    </app-number-input>
                    <app-number-input
                        v-else
                        :value="Number((formData.total_gross * (formData.currency_value || 1))).toFixed(2)" :append="formData.company_currency.code"
                        allow-decimal align="right" disabled>
                    </app-number-input>
                    </div>
                </template>
            </app-access-control>
        </b-row>

        <form-header>{{$t('GUEST')}}</form-header>

        <b-row>
            <app-access-control md="6" lg="3" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_GUEST_FIRST_NAME}"
                                v-slot:default="props">
                <label>{{$t('FIRST_NAME')}}</label>
                <b-form-input
                    type="text"
                    :disabled="props.disabled" v-model="formData.guest.first_name">
                </b-form-input>
            </app-access-control>

            <app-access-control md="6" lg="3" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_GUEST_LAST_NAME}"
                                v-slot:default="props">
                <label>{{$t('LAST_NAME')}}</label>
                <b-form-input
                    type="text"
                    :disabled="props.disabled" v-model="formData.guest.last_name">
                </b-form-input>
            </app-access-control>

            <app-access-control md="6" lg="2" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_GUEST_COUNTRY}"
                                v-slot:default="props">
                <label>{{$t('COUNTRY')}}</label>
                <app-select mode="search"
                            :disabled="props.disabled" v-model="formData.guest.country"
                            :search="searchCountry"
                            :search-clear="true">
                </app-select>
            </app-access-control>


            <app-access-control md="6" lg="2" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_GUEST_EMAIL}"
                                v-slot:default="props">
                <app-input-control :error-object="validation.guest_email">
                    <template v-slot:input="data">
                        <label>{{$t('EMAIL')}}</label>
                        <b-form-input
                            type="text"
                            :disabled="props.disabled" v-model="formData.guest.email" :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </app-access-control>

            <app-access-control md="6" lg="2" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_GUEST_PHONE}"
                                v-slot:default="props">
                <app-input-control :error-object="validation.guest_phone">
                    <template v-slot:input="data">
                        <label>{{$t('PHONE')}}</label>
                        <b-form-input
                            type="text"
                            :disabled="props.disabled" v-model="formData.guest.phone" :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </app-access-control>
        </b-row>

        <b-row>
            <app-access-control lg="2" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_ADULTS}"
                                v-slot:default="props">
                <label>{{$t("ADULTS")}}</label>
                <app-number-input :disabled="props.disabled" v-model="formData.adult_no" :controls="true"
                                  :min="0"></app-number-input>
            </app-access-control>

            <app-access-control lg="2" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_CHILDREN}"
                                v-slot:default="props">
                <label>{{$t("CHILDREN")}}</label>
                <app-number-input :disabled="props.disabled" v-model="formData.child_no" :controls="true"
                                  :min="0"></app-number-input>
            </app-access-control>

            <app-access-control lg="2" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_BABIES}"
                                v-slot:default="props">
                <label>{{$t("BABIES")}}</label>
                <app-number-input :disabled="props.disabled" v-model="formData.baby_no" :controls="true"
                                  :min="0"></app-number-input>
            </app-access-control>
        </b-row>

        <form-header>{{$t('SOURCE')}}</form-header>

        <b-row>
            <app-access-control lg="2" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_DISTRIBUTION}"
                                v-slot:default="props">
                <label>{{$t('DISTRIBUTION')}}</label>
                <app-select mode="search" :search="searchDistribution" search-on-create
                            :disabled="props.disabled" v-model="formData.distribution"></app-select>
            </app-access-control>

            <app-access-control lg="2" md="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_BOOKING_SITE}"
                                v-slot:default="props">
                <label>{{$t('SALES_CHANNEL')}}</label>
                <app-select mode="search" :search="searchChannel" search-on-create :search-empty-item="false"
                            :disabled="props.disabled" v-model="formData.channel"></app-select>
            </app-access-control>
        </b-row>

        <b-row>
            <app-access-control @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: this.C_RESERVATION_ACCOMMODATION_SAVE}"
                                v-slot:default="props">
                <app-button-submit :loading="saving"
                                   :c_permission="C_RESERVATION_ACCOMMODATION_GENERAL_E"
                                   :disabled="props.disabled"></app-button-submit>
            </app-access-control>
        </b-row>

        <b-modal :title="$t('SEND_RESERVATION_NOTIFICATION')" v-model="notificationModal">
            <b-checkbox :checked="false" @change="updateSelectAll">
                {{$t("SELECT_ALL")}}
            </b-checkbox>
            <hr class="p-0 m-0 mb-1 mt-1">
            <b-checkbox-group v-model="sendActionSelected" stacked :options="sendActionVisibleData()"></b-checkbox-group>
            <template v-slot:modal-footer>
                <div class="w-100 d-flex justify-content-between align-items-center">
                    <app-button @click="sendActionEmails" :loading="sendActionLoading"
                                :disabled="sendActionSelected.length === 0"
                                class="mr-3">

                        {{$t('SEND')}}
                    </app-button>
                </div>
            </template>
        </b-modal>
    </b-form>
</template>

<script>
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {cloneDeep} from "lodash";
    import {
        C_RESERVATION_ACCOMMODATION_BOOKING_SITE, C_RESERVATION_ACCOMMODATION_CM_STATUS,
        C_RESERVATION_ACCOMMODATION_GENERAL_E,
        C_RESERVATION_ACCOMMODATION_GENERAL_V, C_RESERVATION_FIELDS_OBJECT
    } from "@/shared/component_permission";
    import FormHeader from "@/components/app/form/FormHeader";
    import {getCountries} from "@/services/location";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getUnitList} from "@/services/unit";
    import {getPropertyList} from "@/services/property";
    import {fetchStatusList} from "@/services/status";
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import TimePicker from "@/components/app/datetime/TimePicker";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import {groupNotify, updateAccommodationReservationHeader} from "@/services/accommodation_reservation";
    import {notifySuccess, toast} from "@/shared/plugins/toastr";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import moment from 'moment'
    import {getChannelList, getDistributionList} from "@/services/distribution";
    import {
        CO_RECIPIENT_TYPE_ACCOUNT_MANAGER,
        CO_RECIPIENT_TYPE_AGENCY,
        CO_RECIPIENT_TYPE_USERS_WITH_COMPANY_UNITS_ACCESS,
        CO_RECIPIENT_TYPE_USERS_WITH_UNIT_ACCESS,
        CONTACT_ACTION_RESERVATION_ARRIVAL_TIME,
        CONTACT_ACTION_RESERVATION_NEW,
        CONTACT_ACTION_RESERVATION_UPDATE,
        CONTACT_RECIPENT_GUEST,
        CONTACT_RECIPENT_PDF,
        DEFAULT_PAGINATION_LIMIT,
        HOME_AWAY,
        LS_PRESETS,
        OBJECT_TYPE_ACCOMMODATION_RESERVATION,
        RES_STATUS_CANCELED
    } from "@/shared/constants";
    import {getCurrencyList} from "@/services/finance";
    import {
        AR_CURRENCY_VALUE,
        AR_GUEST_EMAIL,
        AR_GUEST_PHONE,
        AR_PERIOD,
        AR_UNIT,
        AR_CM_PRODUCT_STATUS
    } from "@/shared/error_codes";
    import AppInputControl from "@/components/app/AppInputControl";
    import {
        AC_ACCOMMODATION_RESERVATION_GENERAL, AC_ACCOMMODATION_RESERVATION_NAVIGATION,
        AC_FEATURE_SETUP
    } from "@/mixins/AccessControl/AccessControlEnumeration";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {fetchCurrencyExchangeRates} from "@/services/finance/currency_exchange";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {getStatus} from "@/services/channel_manager";
    import {EventBus, GE_LOADER_HIDE} from "@/shared/EventBus";
    import {fetchAccessControlData} from "@/services/access";

    const OMIT_REQUEST_FIELDS = [
        "additional_commission",
        "'additional_commission_total",
        "additional_commission_type",
        "additional_commission_vat",
        "additional_commission_vat_type",
        "agency_commission",
        "agency_commission_fix",
        "agency_commission_total",
        "agency_commission_type",
        "commission_total",
        "distribution_commission",
        "distribution_commission_total",
        "distribution_commission_type",
        "distribution_commission_vat",
        "distribution_commission_vat_type",
        "owner_part",
        "platform_fee",
        "platform_fee_type",
        "sales_channel_commission",
        "sales_channel_commission_total",
        "sales_channel_commission_type",
        "sales_channel_vat",
        "sales_channel_vat_type",
        "total",
        "total_nett",
        "transaction_fee",
        "transaction_fee_type",
    ]

    export default {
        name: "ReservationAccommodationGeneral",
        components: {
            AppButton,
            AppAccessControl,
            AppInputControl,
            AppDateRangePicker,
            AppButtonSubmit, TimePicker, FormHeader, AppSelect, AppDatePicker, AppNumberInput
        },
        mixins: [getErrorMessage, AccessControlComponent],
        props: {
            reservation: {
                type: Object,
                required: true
            },
            objectType: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                C_RESERVATION_ACCOMMODATION_GENERAL_V,
                C_RESERVATION_ACCOMMODATION_GENERAL_E,
                formData: null,
                initialFormData: null,
                unitList: [],
                loading: false,
                saving: false,
                unwatch: null,
                statusList: [],
                currencyList: [],
                preloadPropertyList: false,
                validation: {
                    period: AR_PERIOD,
                    guest_phone: AR_GUEST_PHONE,
                    guest_email: AR_GUEST_EMAIL,
                    unit: AR_UNIT,
                    currency_value: AR_CURRENCY_VALUE,
                    cm_product_status: AR_CM_PRODUCT_STATUS
                },
                access_control_fetch_key: AC_ACCOMMODATION_RESERVATION_GENERAL,
                access_control_components: 23,
                access_control_general_context: null,
                ...C_RESERVATION_FIELDS_OBJECT,
                exchangeRateList: [],
                currencyValueSuggestion: {},
                loadingCurrency: false,
                currencyCA: {},
                notificationModal: false,
                sendActions: [
                    // {
                    //     value: 'acm_reservation',
                    //     text: this.$t('RESERVATION_TO_ACCOUNT_MANAGER'),
                    //     request: {
                    //         action: CONTACT_ACTION_RESERVATION_UPDATE,
                    //         recipientTypeList: [CO_RECIPIENT_TYPE_ACCOUNT_MANAGER],
                    //     }
                    // },
                    {
                        value: 'contact_list_reservation',
                        text: this.$t('RESERVATION_TO_CONTACT_LIST'),
                        request: {
                            action: CONTACT_ACTION_RESERVATION_UPDATE,
                            recipientTypeList: [CONTACT_RECIPENT_PDF],
                        }
                    },
                    // {
                    //     value: 'agency_reservation',
                    //     text: this.$t('RESERVATION_TO_AGENCY'),
                    //     request: {
                    //         action: CONTACT_ACTION_RESERVATION_UPDATE,
                    //         recipientTypeList: [CO_RECIPIENT_TYPE_AGENCY],
                    //     }
                    // },
                    {
                        value: 'contact_list_arrival_time',
                        text: this.$t('ARRIVAL_TIME_TO_CONTACT_LIST'),
                        request: {
                            action: CONTACT_ACTION_RESERVATION_ARRIVAL_TIME,
                            recipientTypeList: [
                                CONTACT_RECIPENT_PDF,
                                CO_RECIPIENT_TYPE_USERS_WITH_UNIT_ACCESS,
                                CO_RECIPIENT_TYPE_USERS_WITH_COMPANY_UNITS_ACCESS,
                            ],
                        }
                    },
                    {
                        value: 'guest_confirmation_mail',
                        text: this.$t('CONFIRMATION_TO_GUEST'),
                        request: {
                            action: CONTACT_ACTION_RESERVATION_NEW,
                            recipientTypeList: [CONTACT_RECIPENT_GUEST],
                        }
                    },
                ],
                sendActionSelected: [],
                sendActionLoading: false,
                cmStatusList: [],
                RES_STATUS_CANCELED,C_RESERVATION_ACCOMMODATION_CM_STATUS,
                ac_cm_status_value: false
            }
        },
        computed: {
            period: {
                get() {
                    return {
                        start: this.formData.check_in_date,
                        end: this.formData.check_out_date
                    }
                },
                set({start, end}) {
                    this.formData.check_in_date = start
                    this.formData.check_out_date = end
                }
            },
            nights() {
                return !this.formData.check_in_date || !this.formData.check_out_date ? 0 : moment(this.formData.check_out_date).diff(moment(this.formData.check_in_date), 'days');
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
        },
        watch: {
            reservation: {
                handler(reservation, oldReservation) {
                    this.formData = cloneDeep(reservation)
                    this.initialFormData = cloneDeep(this.formData)

                    fetchCurrencyExchangeRates(reservation.channel_currency.id, {
                        company: this.company.id,
                        with_fixed: true,
                        date: moment(reservation.ts_insert).format('YYYY-MM-DD')
                    }).then(response => {
                        this.exchangeRateList = response.data
                        if (this.exchangeRateList.length) {
                            const index = this.formData.currency_value ? this.exchangeRateList.findIndex(e => Number(e.value) === this.formData.currency_value) : -1
                            if (index < 0) {
                                this.currencyValueSuggestion = {}
                            } else {
                                this.currencyValueSuggestion = this.exchangeRateList[index]
                            }
                        }
                    }, error => (this.showErrorMessages(error))).finally(() => this.loadingCurrency = false)

                    if (!oldReservation || reservation.property.id !== oldReservation.property.id) {
                        this.loading = true
                        getUnitList({property_id: this.formData.property.id, limit: 1000}).then(res => {
                            this.unitList = res.data.items
                        }).finally(() => this.loading = false)
                    }

                    this.unwatch = this.$watch('formData.property.id', (value) => {
                        this.formData.unit = {id: null, name: null}
                        if (value) {
                            this.loading = true
                            getUnitList({property_id: value, limit: 1000}).then(res => {
                                this.unitList = res.data.items
                            }).finally(() => this.loading = false)
                        } else {
                            this.unitList = []
                            this.loading = false
                        }
                    })
                },
                immediate: true
            },
            'formData.channel_currency': {
                handler(newObject, previousObject) {
                    if (newObject.id === this.company.main_currency.id) {
                        this.exchangeRateList = []
                        this.formData.currency_value = 1
                        return
                    }

                    if (newObject.id !== previousObject.id) {
                        this.exchangeRateList = []
                        this.currencyValueSuggestion = {}
                        this.loadingCurrency = true
                        this.formData.currency_value = null
                        fetchCurrencyExchangeRates(newObject.id, {
                            company: this.company.id,
                            with_fixed: true
                        }).then(response => {
                            this.exchangeRateList = response.data
                            if (this.exchangeRateList.length) {
                                // this.currencyValueSuggestion = this.exchangeRateList[0]
                            } else {
                                this.formData.currency_value = null
                            }
                        }, error => (this.showErrorMessages(error))).finally(() => this.loadingCurrency = false)
                    }
                }
            },
            'formData.currency_value': {
                handler(value) {
                    const index = value ? this.exchangeRateList.findIndex(e => Number(e.value) === value) : -1
                    if (index < 0) {
                        this.currencyValueSuggestion = {}
                    } else {
                        this.currencyValueSuggestion = this.exchangeRateList[index]
                    }
                }
            },
            'formData.status.id': {
                handler() {
                    this.formData.cm_product_status.id = null
                    this.fetchAccessControl()
                }
            }
        },
        methods: {
            sendActionVisibleData(){
                let visibleData = cloneDeep(this.sendActions)
                let selectedReservation = this.reservation
                if (selectedReservation.hasOwnProperty('check_in_time') && selectedReservation.check_in_time === null) {
                    const index = visibleData.findIndex(el => el.value === 'contact_list_arrival_time')
                    if (index > -1) {
                        visibleData.splice(index, 1)
                    }
                }
                return visibleData
            },
            save() {
                this.saving = true
                updateAccommodationReservationHeader(this.reservation.id, this.prepareUpdateReservationRequest()).then(response => {
                  if (
                      this.reservation.check_in_date !== this.formData.check_in_date ||
                      this.reservation.check_out_date !== this.formData.check_out_date ||
                      this.reservation.check_in_time !== this.formData.check_in_time ||
                      this.reservation.check_out_time !== this.formData.check_out_time
                  ) {
                    this.$emit('reloadScheduler')
                  }

                  if (this.reservation.currency_value !== this.formData.currency_value) {
                    this.$emit('reloadFinance')
                  }
                    this.$emit('update', response.data)
                    this.$emit('saved', response.data)
                    if(response.data.show_dialog){
                        this.notificationModal = response.data.show_dialog
                    }
                    notifySuccess()
                }).catch(error => {
                    this.showErrorMessages(error, this.validation)
                }).finally(() => this.saving = false)
            },
            searchCountry(value) {
                return getCountries({
                    name: value
                })
            },
            searchProperty(value) {
                return getPropertyList({name: value, company_id: this.reservation.company_id}).then(response => {
                    return {data: response.data.items}
                })
            },
            searchDistribution() {
                return getDistributionList({company: this.reservation.company_id, for_reservation: 1, sorted: 1})
            },
            searchChannel() {
                return getChannelList({with_icons: 0, for_reservation: 1}).then(response => {
                    return {
                        data: response.data.map(el => {
                            return {id: el.id, name: el.name}
                        })
                    }
                })
            },
            searchCurrency() {
                return getCurrencyList()
            },
            getExchangeRateText(item) {
                if (this.loadingCurrency) return ''
                const text = item.exchange_channel && item.exchange_channel.name
                    ? `${item.exchange_channel.name} ${moment(item.date).format(this.$t('DATE.DATE_FORMAT'))}`
                    : this.$t('FIXED_EXCHANGE_RATE')
                return item.value ? `${item.value} (${text})` : this.$t('CUSTOM')
            },
            updateSelectAll($event) {
                if ($event) {
                    this.sendActionSelected = this.sendActions.map(el => el.value)
                } else {
                    this.sendActionSelected = []
                }
            },
            async sendActionEmails() {
                this.sendActionLoading = true
                let filterReq = {reservation: this.formData.id}
                let sendActionArray = []

                for (let index = 0; index < this.sendActionSelected.length; index++) {
                    const selectedItem = this.sendActionSelected[index]
                    let requestItem = this.sendActions.find(el => {
                        return el.value === selectedItem
                    })
                    sendActionArray.push({action: requestItem.request.action, recipient_type: requestItem.request.recipientTypeList})
                }

                await groupNotify({action_recipient_type_list: {...sendActionArray}, ...filterReq}).then(() => {
                    notifySuccess()
                }, (error) => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.sendActionLoading = false
                    this.notificationModal = false
                    this.sendActionSelected = []
                })
            },
            fetchAccessControl() {
                let request = {
                    key: AC_ACCOMMODATION_RESERVATION_GENERAL,
                    context: {
                        company: this.company.id,
                        status: this.formData.status.id,
                        reservation: this.formData.id
                    },
                    data: []
                }

                request.data.push({
                    uid: C_RESERVATION_ACCOMMODATION_CM_STATUS,
                    function: C_RESERVATION_ACCOMMODATION_CM_STATUS,
                })
                fetchAccessControlData(AC_ACCOMMODATION_RESERVATION_GENERAL, request).then(response => {
                    this.ac_cm_status_value = response.data[C_RESERVATION_ACCOMMODATION_CM_STATUS].visible
                })
            },
            prepareUpdateReservationRequest() {
                let request = {}
                if (!!this.formData && typeof this.formData === "object") {
                    for (const [key, value] of Object.entries(this.formData)) {
                        if (OMIT_REQUEST_FIELDS.indexOf(key) < 0) {
                            this.$set(request, key, value)
                        }
                    }
                }
                return request
            },
        },
        created() {
            this.$emit('created', true)

            this.access_control_general_context = {
                company: this.company.id,
                reservation: this.reservation.id
            }

            fetchStatusList(this.objectType).then(res => {
                this.statusList = res.data
            }).catch(() => this.statusList = [])

            getStatus({
                distribution: HOME_AWAY,
                object_type: OBJECT_TYPE_ACCOMMODATION_RESERVATION
            }).then(response => {
                this.cmStatusList = response.data
            })

            let storagePresets = localStorage.getItem(LS_PRESETS)
            if (storagePresets) {
                storagePresets = JSON.parse(storagePresets)
                if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                    this.preloadPropertyList = true
                }
            }
            this.fetchAccessControl()
        },
        beforeRouteUpdate(to, from, next) {
            if (typeof this.unwatch === 'function') {
                this.unwatch()
            }
            next()
        }
    }
</script>

<style scoped>

</style>
